/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Roboto';
  // src: url('../fonts/AvenirLTStd-Light.eot');
  src: url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 100 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  // src: url('../fonts/AvenirLTStd-Light.eot');
  src: url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 400 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  // src: url('../fonts/AvenirLTStd-Light.eot');
  src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700 900;
  font-style: normal;
}

html,
body {
  color: var(--text-color);
  background-color: #e5e5e5;
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// /* width */
// ::-webkit-scrollbar {
//   width: 1px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   border-radius: 1px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   border-radius: 1px;
// }

@media screen and (max-width: 540px) {
  ::-webkit-scrollbar {
    width: 7px;
  }
}

@media (max-width: 30em) {
  html,
  body {
    background-color: white !important;
  }
}

.container {
  width: 80% !important;
  margin: auto;
  max-width: var(--max-width);
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 900px) {
  .container {
    width: 100% !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .container {
    width: 90% !important;
  }
}

.errorMsg {
  color: var(--error-text-color);
  font-size: 1rem;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-multipline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
